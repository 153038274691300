<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Historial</h3>
                  <div class="nk-block-des text-soft">
                    <p>Se encontraron un total de {{ total }}.</p>
                  </div>
                </div><!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                  <div class="toggle-wrap nk-block-tools-toggle">
                    <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em
                        class="icon ni ni-menu-alt-r"></em></a>
                  </div><!-- .toggle-wrap -->
                </div><!-- .nk-block-head-content -->
              </div><!-- .nk-block-between -->
            </div><!-- .nk-block-head -->
            <TableGrid :rows="rows" :filters="filters" :total="total" @update="update">

              <template #filters>
                <div class="card-tools between-start overflow-auto">
                  <div v-for="filter in filters" class="mr-3" :key="filter.key">
                    <input v-if="filter.type === 'text'" type="text" autocomplete="new" class="form-control w-min-150px"
                           :placeholder="filter.label" v-model.lazy="filter.value">
                    <SelectServicio v-if="filter.label === 'Servicio'" class="form-control w-min-150px"
                                    v-model:servicio="filter.value" :all="true" placeholder="Servicio">
                    </SelectServicio>
                    <input v-if="filter.type === 'date'" type="date" autocomplete="new" class="form-control w-min-150px"
                           :placeholder="filter.label" v-model.lazy="filter.value">
                  </div>
                </div>
              </template>
              <template #header>
                <div class="nk-tb-col"><span class="sub-text">ID</span></div>
                <div class="nk-tb-col"><span class="sub-text">Recorrido</span></div>
                <div class="nk-tb-col"><span class="sub-text">Empleado</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Servicio</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Fecha inicio</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Fecha fin</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Duración</span></div>
                <div class="nk-tb-col text-right"><span class="sub-text">Acciones</span></div>
              </template>
              <template v-slot:row="slot">
                <div class="nk-tb-col">
                  <span>{{ slot.row.id_rondin }}</span>
                </div>
                <!-- <div class="nk-tb-col">
                  <router-link :to="'/sistema/recorridos/editar/' + slot.row.id_recorrido">
                    <span>{{ slot.row.nombre }}</span>
                  </router-link>
                </div> -->
                <div class="nk-tb-col">
                  <span>{{ slot.row.nombre }}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{ slot.row.empleado }}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{ slot.row.servicio }}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{ slot.row.start_date}}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{ slot.row.end_date}}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{ slot.row.date_diff_format}}</span>
                </div>
                <div class="nk-tb-col nk-tb-col-tools">
                  <ul class="nk-tb-actions gx-2">
                    <li>
                      <div class="drodown">
                        <a href="#" class="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em
                            class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">

                            <li><a href="#" @click.prevent="remove(slot.row)"><em
                                class="text-danger icon ni ni-user-remove"></em><span>Eliminar Recorrido</span></a></li>
                            <li><a href="#" @click.prevent="generatePdf(slot.row)"><em
                                class="text-danger icon ni ni-file-pdf"></em><span>Descargar PDF</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </TableGrid>
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import {reactive, ref, toRefs, defineComponent} from "vue";
import AppView from "@/components/sistema/AppView";
import TableGrid from "@/components/TableGrid";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
import SelectServicio from "@/components/globales/SelectServicio.vue";

export default defineComponent({
  name: "Recorridos",
  components: {SelectServicio, TableGrid, AppView},
  setup() {
    const router = useRouter();

    const state = reactive({
      rows: [],
      total: 1
    });
    const filters = ref([
      {
        key: 'nombre',
        label: 'Recorrido',
        value: '',
        type: 'text'
      },{
        key: 'empleado',
        label: 'Empleado',
        value: '',
        type: 'text'
      },
      {
        key: 'id_servicio',
        label: 'Servicio',
        value: '',
        type: 'select'
      },{
        key: 'fecha_inicio',
        label: 'Fecha inicio',
        value: '',
        type: 'date'
      },{
        key: 'fecha_fin',
        label: 'Fecha fin',
        value: '',
        type: 'date'
      }
    ]);

    const {loading, make} = useApi();

    async function update(config) {

      loading.message = 'Obteniendo información';

      let {data} = await make('historial/get-all', config);
      if (data === null) {
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if (data.error) {
          await Swal.fire('Error', data.message, 'error');
        } else {
          state.rows = data.rows;
          state.total = Number(data.total);
        }
      }
    }

    async function remove(recorrido) {
      Swal.fire({
        title: 'Eliminar recorrido',
        text: '¿Estás seguro que deseas eliminar el recorrido de ' + recorrido.empleado + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Eliminando recorrido';
          let {data} = await make('historial/delete', {id_rondin: recorrido.id_rondin});
          if (data === null) {
            await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              await Swal.fire('Error', data.message, 'error');
            } else {
              router.go(0);
            }
          }
        }
      });

    }

    async function generatePdf(recorrido) {

      Swal.fire({
        title: 'Generar Historial',
        text: '¿Estás seguro que deseas generar el PDF ' + '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';

          let {data} = await make('historial/create-pdf', {recorrido});

          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              window.open(data.url, '_blank').focus();
            }
          }
        }
      });


    }

    return {...toRefs(state), filters, remove, update, generatePdf}
  }
});
</script>

<style scoped></style>
